/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import ControlledWidthContainer from '../../../components/Community/Sections/ControlledWidthContainer/ControlledWidthContainer';
import Layout from '../../../components/Layout/Layout';
import { LOGIN_DESCRIPTIONS } from '../../../components/Login/Login_Descriptions';
import {
    SnowplowPageViewTracker,
    PAGE_VIEW_SECTIONS,
    PAGE_VIEW_TYPES,
} from '../../../components/Analytics/Snowplow/SnowplowPageViewTracker';

import './Login.scss';
import {
    CREATE_LAYOUT,
    EMBED_MODE,
    FACEBOOK_REAUTHORIZATION_CONFIRMATION_LAYOUT,
    FACEBOOK_REAUTHORIZATION_LAYOUT,
    LOGIN_LAYOUT,
    RESET_PASSWORD_CONFIRMATION_LAYOUT,
    RESET_PASSWORD_LAYOUT,
} from '../../../components/Login/LoginLayoutComponent/Layouts/LogInLayout/constants';
import LoginLayoutComponent from '../../../components/Login/LoginLayoutComponent/LoginLayoutComponent';

class Login extends React.PureComponent {
    render() {
        const metrics = this.props.loginMetrics;
        let callToActionSubTitle;
        if (metrics && metrics.login_trigger) {
            callToActionSubTitle = LOGIN_DESCRIPTIONS[metrics.login_trigger];
            if (this.props.callToActionSubTitle) {
                callToActionSubTitle = this.props.callToActionSubTitle;
            }
        }
        const { PAGE_PROPS } = this.props;
        return (
            <div className="page-User-Login">
                <SnowplowPageViewTracker
                    pageSection={PAGE_VIEW_SECTIONS.LOGIN}
                    pageType={PAGE_VIEW_TYPES.USER_INPUT}
                />
                <Layout
                    PAGE_PROPS={this.props.PAGE_PROPS}
                    hasChangingContainer={false}
                >
                    <div className="wrapper">
                        <div className="embedded-login">
                            <ControlledWidthContainer>
                                <LoginLayoutComponent
                                    inviteHash={this.props.inviteHash}
                                    connectionInviteHash={
                                        this.props.connectionInviteHash
                                    }
                                    groupInviteHash={this.props.groupInviteHash}
                                    employerInviteHash={
                                        this.props.employerInviteHash
                                    }
                                    callToActionSubTitle={callToActionSubTitle}
                                    displayMode={EMBED_MODE}
                                    layoutType={this.props.layoutType}
                                    auth0={PAGE_PROPS.auth0}
                                    loginRedirectPath={
                                        this.props.loginRedirectPath ||
                                        PAGE_PROPS.url.path
                                    }
                                    redirectUri={PAGE_PROPS.redirectUri}
                                    loginMetrics={this.props.loginMetrics}
                                    invitationToken={this.props.invitationToken}
                                    incompleteRegistrationId={
                                        this.props.incompleteRegistrationId
                                    }
                                />
                            </ControlledWidthContainer>
                        </div>
                    </div>
                </Layout>
            </div>
        );
    }
}

Login.propTypes = {
    /**
     * Page props
     */
    PAGE_PROPS: PropTypes.object,
    /**
     * type of login layout
     */
    layoutType: PropTypes.oneOf([
        CREATE_LAYOUT,
        LOGIN_LAYOUT,
        RESET_PASSWORD_LAYOUT,
        RESET_PASSWORD_CONFIRMATION_LAYOUT,
        FACEBOOK_REAUTHORIZATION_LAYOUT,
        FACEBOOK_REAUTHORIZATION_CONFIRMATION_LAYOUT,
    ]),
    /**
     * sign up success redirect uri
     */
    loginRedirectPath: PropTypes.string,
    /**
     * url path header
     */
    redirectUri: PropTypes.string,
    /**
     * Object containing any additional analytics metrics we need to fire with the auth open pixel
     */
    loginMetrics: PropTypes.object,
    /**
     * token from invitations such as  email
     */
    invitationToken: PropTypes.string,
    /**
     * if user has filled out a registration survey, this would be her temp survey id
     */
    incompleteRegistrationId: PropTypes.string,
    inviteHash: PropTypes.string,
    groupInviteHash: PropTypes.string,
    connectionInviteHash: PropTypes.string,
    employerInviteHash: PropTypes.string,
    callToActionSubTitle: PropTypes.string,
};

Login.defaultProps = {
    PAGE_PROPS: undefined,
    layoutType: CREATE_LAYOUT,
    incompleteRegistrationId: null,
    invitationToken: null,
    inviteHash: undefined,
    groupInviteHash: null,
    connectionInviteHash: null,
    loginMetrics: undefined,
    loginRedirectPath: undefined,
    redirectUri: undefined,
    employerInviteHash: null,
    callToActionSubTitle: undefined,
};

export default Login;
