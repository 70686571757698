import PropTypes from 'prop-types';
import React from 'react';
import './ControlledWidthContainer.scss';

export default class ControlledWidthContainer extends React.PureComponent {
    constructor(props) {
        super();
    }

    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };

    static defaultProps = {};

    render() {
        return (
            <div
                className={`component-Community-Sections-ControlledWidthContainer ${this.props.className}`}
            >
                {this.props.children}
            </div>
        );
    }
}
